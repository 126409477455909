import { FC, useEffect, useState } from 'react';
import styled from 'styled-components';
import constants from '../shared/constants';
import { PageContentWrapper } from '../shared/styles/wrappers';

const IntroWrapper = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-between;
    align-items: start;
    p {
        margin: 0 auto 60px auto;
    }
    h2 {
        margin-bottom: 15px;
    }
    margin-bottom: 50px;
    @media screen and (max-width: ${constants.breakpoints.miniTablet}) {
        flex-direction: column;
        margin-top: 50px;
        p {
            margin-bottom: 30px;
        }
    }
`;

const JobWrapper = styled.div`
    p {
        margin: 0 auto 15px auto;
    }
    li {
        margin: 0px 20px;
    }
`;

const ImageWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const ImageTxt = styled.span`
    font-size: 13px;
    line-height: 17px;
    letter-spacing: 0.03em;
    max-width: 238px;
    color: rgba(255, 255, 255, 0.6);
    margin-top: 45px;
`;
const ColumnWrapper = styled.div`
    width: calc(50% - 20px);
    display: flex;
    flex-direction: column;
    justify-content: center;
    img {
        max-width: 400px;
        margin: auto;
    }
    @media screen and (max-width: ${constants.breakpoints.miniTablet}) {
        width: 100%;
        img {
            margin-bottom: 30px;
            width: 100%;
        }
    }
`;
const StyledHeader = styled.h1`
    margin-bottom: 75px;
    @media screen and (max-width: ${constants.breakpoints.miniTablet}) {
        margin-bottom: 30px;
    }
`;

const Video = styled.video`
    width: 500px;
    height: auto;
    max-width: calc(100% - 20px);
    margin: auto;
`;

const Title = styled.span<{ open?: boolean }>`
    font-weight: ${(props) => (props.open ? '700' : 'normal')};
    h2 {
        border-bottom: 2px solid rgba(255, 255, 255, 0.2);
    }
    cursor: pointer;
`;

interface IJobItem {
    title: String;
    description: JSX.Element;
    defaultOpen: boolean;
}

const JobItem: FC<IJobItem> = ({ title, description, defaultOpen }) => {
    const [open, setOpen] = useState<boolean>(defaultOpen);
    return (
        <div>
            <Title open={open} onClick={() => setOpen(!open)}>
                <h2>
                    {open ? '-' : '+'} {title}
                </h2>
            </Title>
            {open && <>{description}</>}
        </div>
    );
};

const JobList: IJobItem[] = [
    {
        title: 'Master or Bachelor Thesis',
        description: (
            <JobWrapper>
                <p>
                    <b>NB:</b> We have reached our limit for supervised theses for the academic year 2024/2025. You can
                    still reach out for 2025/2026.
                </p>
                <p>
                    Are you a student that specializes in geoinformatics, computer science, cybernetics, marine
                    technology or similar fields of study? You should consider writing a thesis in collaboration with
                    us! Vake has connections to scientific communities at NTNU, NHH, and UIT, and believes student
                    research adds great value to our mission. We have previously supervised twelve master theses, and
                    are seeking new engaged students to do projects within computer vision, machine learning, onboard
                    satellite processing, vessel position prediction or time-series analysis.
                </p>
            </JobWrapper>
        ),
        defaultOpen: false,
    },
    // {
    //     title: 'Summer Internship',
    //     description: (
    //         <JobWrapper>
    //             <p>
    //                 <b>NB:</b> We have filled the 2024 summer internship team. Applications for Summer 2025 will open
    //                 August 2024.
    //             </p>
    //             {/* <p>
    //                 <ul>
    //                     <li>Deadline: We will assess applicants continously</li>
    //                     <li>Duration: 6 weeks</li>
    //                 </ul>
    //             </p> */}
    //             <p>
    //                 Do you want to work with machine learning, space technology and data fusion this summer? After
    //                 summer internships in 2020, 2021, 2022 and 2023, we will once again repeat the success. Vake is
    //                 looking for interns to further develop our platform in collaboration with our experienced software
    //                 developers.
    //             </p>
    //             <p>
    //                 You will be a part of integrating new satellite sources, training deep learning models and work
    //                 full-stack on our API and frontend solutions. We will tailor tasks to your interests, and we
    //                 guarantee you will learn a lot. As a summer intern at Vake, you will also be a part of a greater
    //                 summer program with Iterate, where you will meet even more students and mentors through social and
    //                 educational events.
    //             </p>
    //         </JobWrapper>
    //     ),
    // },
    {
        title: 'Winter Internship',
        description: (
            <JobWrapper>
                <p>
                    <ul>
                        <li>The first interviews will be held in Trondheim 25. September, so be quick!</li>
                        <li>Duration: 6 weeks January - February</li>
                        <li>Application details: Send application letter and resume to connect@vake.ai</li>
                    </ul>
                </p>
                <p>
                    After five successful summer internships, we will now open for winter internship positions for the
                    first time. We are looking for motivated interns to join our team of experienced software developers
                    crafting our solution.
                </p>
                <p>
                    You will be a part of integrating new satellite sources, training deep learning models or work
                    full-stack on our API and frontend solutions. We will tailor tasks to your interests, and we
                    guarantee you will learn a lot.
                </p>
            </JobWrapper>
        ),
        defaultOpen: true,
    },
];

const Careers: FC = () => {
    useEffect(() => {
        document.title = 'Careers';
    }, []);

    return (
        <PageContentWrapper>
            <IntroWrapper>
                <ColumnWrapper>
                    <StyledHeader>Careers</StyledHeader>
                    <p>
                        Vake is eager to expand the team in Oslo. We are currently looking for curious winter students,
                        and final-year students who want to write their bachelor or master thesis in collaboration with
                        us.
                        <br />
                        <br />
                        As an emerging machine learning company in the space domain, we believe we can offer exciting
                        opportunities working with cutting edge technology and innovation. It also doesn’t hurt that we
                        have really nice offices in the city centre...{' '}
                        <a href="mailto:connect@vake.ai">Reach out and introduce yourself</a>, lets make something
                        happen!
                    </p>

                    {JobList.map((item, i) => (
                        <JobItem key={`job_${i}`} {...item} />
                    ))}
                </ColumnWrapper>
                <ImageWrapper>
                    <Video autoPlay muted loop>
                        <source src="images/about/Sentinel.mp4" type="video/mp4" />
                    </Video>
                    <ImageTxt>Visualization of Sentinel 2A in orbit, one of our main data sources</ImageTxt>
                </ImageWrapper>
            </IntroWrapper>
        </PageContentWrapper>
    );
};

export default Careers;
